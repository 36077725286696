<template>
    <div class="pages p20">
        <div class="title-btn-block">
            <div class="title-block">{{ $t("message.my_requests") }}</div>
            <div>
                <router-link :to="{ name: 'Home' }">
                    <div class="my-btn cr1 el-button">
                        <i class="el-icon-d-arrow-left"></i> {{$t('message.back')}}
                    </div>
                </router-link>
            </div>
        </div>

        <div class="content-block">
            <!-- <div class="datetime-end bg p20 mb20">
                <el-divider content-position="left"> Мои запросы </el-divider>
                <el-row :gutter="20">
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                        <div class="seloction mb20">
                            <el-select
                                v-model="region"
                                class="w-100"
                                popper-class="my-data-khan"
                            >
                                <el-option
                                    label="Отпроситься"
                                    value="shanghai1"
                                ></el-option>
                                <el-option
                                    label="Аванс"
                                    value="beijing1"
                                ></el-option>
                            </el-select>
                        </div>
                    </el-col>
                </el-row>
            </div> -->

            <div class="datetime-end bg p20 mb20">
                <el-divider content-position="left">{{ $t("message.my_permission_requests") }}</el-divider>
                <div class="scrol-table crmhome__scroll my-table">
                  <table >
                        <thead>
                            <tr>
                                <th>{{ $t("message.user") }}</th>
                                <th>{{ $t("message.reason") }}</th>
                                <th>{{ $t("message.left_date") }}</th>
                                <th>{{ $t("message.come_date") }}</th>
                                <th>{{ $t("message.status") }}</th>
                                <th>{{ $t("message.comment") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="assent in assents" :key="assent.id">
                                <td>{{assent.staff.name}} {{assent.staff.last_name}} {{assent.staff.middle_name}}</td>
                                <td>{{assent.reason.name}}</td>
                                <td>{{assent.left_date}} {{assent.left_time}}</td>
                                <td>{{assent.coming_date}} {{assent.coming_time}}</td>
                                <td v-if="assent.status == 'accept'" style="background: #00a838">{{$t('message.received')}}</td>
                                <td v-if="assent.status == 'deny'" style="background: #ba0615">{{$t('message.denied')}}</td>
                                <td v-if="assent.status == 'waiting'" style="background: #E6A23C">{{ $t("message.waiting") }}</td>
                                <td>{{assent.comment}}</td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="datetime-end bg p20 mb20">
                <el-divider content-position="left"> {{ $t("message.my_advance_requests") }} </el-divider>

                <div class="my-table">
                    <table>
                        <thead>
                            <tr>
                               <th>{{ $t("message.user") }}</th>
                                <th>{{ $t("message.advance_amount") }}</th>
                                <th>{{ $t("message.request_data") }}</th>
                                <th>{{ $t("message.status") }}</th>
                                <th>{{ $t("message.comment") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="salary in advance_salary" :key="salary.id">
                                <td>{{salary.staff.name}} {{salary.staff.last_name}} {{salary.staff.middle_name}}</td>
                                <!-- <td>{{salary.reason.name}}</td> -->
                                <!-- <td>{{salary.left_date}} {{salary.left_time}}</td> -->
                                <!-- <td>{{salary.coming_date}} {{salary.coming_time}}</td> -->
                                <td>{{salary.amount}}</td>
                                <td v-if="salary.periot == 'today'" >{{'Bugunga'}}</td>                                
                                <td v-if="salary.periot == 'tomorrow'" >{{'Ertaga'}}</td>                                
                                <td v-if="salary.periot == 'this_week'" >{{'Shu haftaga'}}</td>                                
                                <td v-if="salary.status == 'accept'" style="background: #00a838">{{$t('message.received')}}</td>
                                <td v-if="salary.status == 'waiting'" style="background: #E6A23C">{{ $t("message.waiting") }}</td>
                                <td>{{salary.comment}}</td>
                            </tr>
                           
                        </tbody>
                    </table>
                </div>
            </div>
              <div class="datetime-end bg p20 mb20">
                <el-divider content-position="left"> {{$t('message.my_vacation_requests')}} </el-divider>
                <div class="my-table">
                    <table>
                        <thead>
                            <tr>
                               <th>{{ $t("message.user") }}</th>
                                <th>{{ $t("message.reason") }}</th>
                                <th>{{ $t("message.left_date") }}</th>
                                <th>{{ $t("message.come_date") }}</th>
                                <th>{{ $t("message.status") }}</th>
                                <th>{{ $t("message.comment") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="vacation in vacations" :key="vacation.id">
                                <td>{{vacation.staff.name}} {{vacation.staff.last_name}} {{vacation.staff.middle_name}}</td>
                                <td>{{vacation.reason.name}}</td>
                                <td>{{vacation.left_date}} </td>
                                <td>{{vacation.coming_date}}</td>
                                  <td v-if="vacation.status == 'accept'" style="background: #00a838">{{$t('message.received')}}</td>
                                <td v-if="vacation.status == 'deny'" style="background: #ba0615">{{$t('message.denied')}}</td>
                                <td v-if="vacation.status == 'waiting'" style="background: #E6A23C">{{ $t("message.waiting") }}</td>
                                <td>{{vacation.comment}}</td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getItem } from "@/utils/storage";

import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      salarys:null,
      complaints:[],
      advance_salary:null
    };
  },
  mounted() {
    this.getTable()
  },
  methods: {
    ...mapActions({
      updateList: "myRequests/index",
    }),

    getTable() {
      this.updateList(getItem("userId"))
        .then((res) => {
          this.assents = res.take_assent
          this.vacations = res.vacation
          this.advance_salary = res.advance_salary
        })
        .catch((err) => {
          this.$alert(err);
        });
    },
  },
};
</script>
